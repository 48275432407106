<template>
  <a-card :bordered="false" class="card-product header-solid mb-24">
    <div class="content">
      <a-row class="title">
        <h2 style="color: #4493f7;">24H内完成新用户指引&nbsp;&nbsp;&nbsp;&nbsp;获取3天体验VIP</h2>
        <p style="font-size: 14px; color: #ec5b57; font-weight: bold;">
        剩余时间：{{ hours }}小时{{ minutes }}分{{ seconds }}秒
      </p>
      </a-row>

      <a-row class="steps" v-if="!ip_button_click || !api_button_click || !task_button_click || !settings_button_click">
        <a-row class="step">
          <a-row class="step-row">
            <h4>
              <a-icon type="check-circle" theme="outlined" style="color: #BFBFBF;margin-right: 10px;" v-if="!ip_button_click"/>
              <a-icon type="check-circle" theme="outlined" style="color: #52C41A;margin-right: 10px;" v-else/>
              第一步：获取3天体验代理IP
            </h4>
          </a-row>
          <a-row class="step-row" style="margin-top: 10px">
            <a-button type="danger" size="small" v-if="!ip_button_click" @click="clickButton('ip_button_click')">领取体验IP</a-button>
            <router-link to="/proxy" v-else>
              <a-button type="primary" size="small">
                前往查看IP
                <svg style="margin-left: 10px" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd"></path></svg>
              </a-button>
            </router-link>
          </a-row>
        </a-row>

        <a-row class="step">
          <a-row class="step-row">
            <h4>
              <a-icon type="check-circle" theme="outlined" style="color: #BFBFBF;margin-right: 10px;" v-if="!api_button_click"/>
              <a-icon type="check-circle" theme="outlined" style="color: #52C41A;margin-right: 10px;" v-else/>
              第二步：添加交易所API&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </h4>
          </a-row>
          <a-row class="step-row">
            <a-row class="step-row-line">
              <p style="margin: 0;">如何添加API？</p>
              <a href="https://support.qq.com/products/647309/faqs-more/?id=154642" target="_blank">点击查看<a-icon type="link" theme="outlined" /></a>
            </a-row>
            <a-row class="step-row-line" style="margin-top: 15px">
              <router-link to="/addapi" blank>
                <a-button type="primary" size="small" @click="clickButton('api_button_click')">
                  前往添加API
                  <svg style="margin-left: 10px" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd"></path></svg>
                </a-button>
              </router-link>
            </a-row>
          </a-row>
        </a-row>

        <a-row class="step">
          <a-row class="step-row">
            <h4>
              <a-icon type="check-circle" theme="outlined" style="color: #BFBFBF;margin-right: 10px;" v-if="!task_button_click"/>
              <a-icon type="check-circle" theme="outlined" style="color: #52C41A;margin-right: 10px;" v-else/>
              第三步：创建跟单任务&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </h4>
          </a-row>
          <a-row class="step-row">
            <a-row class="step-row-line">
              <p style="margin: 0;">如何创建跟单？</p>
              <a href="https://txc.qq.com/products/647309/faqs-more/?id=154656" target="_blank">点击查看<a-icon type="link" theme="outlined" /></a>
            </a-row>
            <a-row class="step-row-line" style="margin-top: 15px">
              <router-link to="/addtask">
                <a-button type="primary" size="small" @click="clickButton('task_button_click')">
                  前往创建跟单
                  <svg style="margin-left: 10px" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd"></path></svg>
                </a-button>
              </router-link>
            </a-row>
          </a-row>
        </a-row>

        <a-row class="step">
          <a-row class="step-row">
            <h4>
              <a-icon type="check-circle" theme="outlined" style="color: #BFBFBF;margin-right: 10px;" v-if="!settings_button_click"/>
              <a-icon type="check-circle" theme="outlined" style="color: #52C41A;margin-right: 10px;" v-else/>
              第四步：设置跟单消息通知&nbsp;&nbsp;&nbsp;&nbsp;
            </h4>
          </a-row>
          <a-row class="step-row">
            <a-row class="step-row-line" style="margin-top: 10px">
              <router-link to="/settings">
                <a-button type="primary" size="small" @click="clickButton('settings_button_click')">
                  前往设置通知
                  <svg style="margin-left: 10px" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd"></path></svg>
                </a-button>
              </router-link>
            </a-row>
          </a-row>

        </a-row>
      </a-row>

      <a-row class="title" v-else>
        <h2 style="color: #ec5b57;">恭喜完成指引</h2>
        <a-button type="danger" size="large" @click="completeGuide(0)">领取3天体验VIP</a-button>
      </a-row>

    </div>
  </a-card>
</template>

<script>
import http from "../utils/http"

export default {
  name: "NewUser",
  data() {
    return {
      is_new_user: false,
      targetTime: '',
      ip_button_click: false,
      api_button_click: false,
      task_button_click: false,
      settings_button_click: false,

      endTime: 0,   // 倒计时结束的时间（目标时间 + 24小时）
      hours: "00",   // 剩余小时
      minutes: "00", // 剩余分钟
      seconds: "00", // 剩余秒数
      timer: null,   // 定时器ID
    }
  },
  created() {
    this.fetchData();
  },
  mounted() {
    // 开始倒计时
    this.startCountdown();
  },
  methods: {
    // 点击按钮
    async clickButton(e) {

      const response = await http.patch('/api/guide/?token=' + localStorage.getItem("token"), {button: e});
      if (response.data.code === 0) {
        switch (e) {
          case 'ip_button_click':
            this.ip_button_click = true;
            this.$message.success(response.data.data);
            break;
          case 'api_button_click':
            this.api_button_click = true;
            break;
          case 'task_button_click':
            this.task_button_click = true;
            break
        }
      } else {
        this.$message.error("引导完成失败，请稍后再试！");
      }
    },

    // 加载数据
    async fetchData() {
      const response = await http.get('/api/guide/?token=' + localStorage.getItem("token"));
      if (response.data.code === 0) {
        this.is_new_user = response.data.data.is_new_user;
        this.targetTime = new Date(response.data.data.create_datetime).getTime();
        this.ip_button_click = response.data.data.ip_button_click;
        this.api_button_click = response.data.data.api_button_click;
        this.task_button_click = response.data.data.task_button_click;
        this.settings_button_click = response.data.data.settings_button_click;
        // 初始化倒计时结束时间（目标时间 + 24小时）
        this.endTime = this.targetTime + 24 * 60 * 60 * 1000; // 24小时的毫秒数
        const currentTime = new Date().getTime();
        const timeLeft = this.endTime - currentTime;
        if (timeLeft <= 0) {
          await this.$router.push('/dashboard');
          await this.completeGuide(1)
        }


        if (!this.is_new_user) {
          await this.$router.push('/dashboard');;
        }
      } else {
        return;
      }
    },

    // 完成引导
    async completeGuide(e) {
      const response = await http.post('/api/guide/?token=' + localStorage.getItem("token"), {type: e});
      if (response.data.code === 0) {
        this.$message.success(response.data.data);
        // 刷新页面
        window.location.reload();
      } else {
        this.$message.error("引导完成失败，请稍后再试！");
      }
    },

    // 倒计时
    startCountdown() {
      this.timer = setInterval(() => {
        // 获取当前时间的时间戳
        const currentTime = new Date().getTime();
        // console.log('当前：',currentTime);
        // console.log('创建时间：',this.targetTime);
        // console.log('24小时后：',this.endTime);

        // 计算目标时间与当前时间的差值（毫秒）
        const timeLeft = this.endTime - currentTime;

        if (timeLeft <= 0) {
          // 如果倒计时结束，则停止计时并显示 "00:00:00"
          this.hours = "00";
          this.minutes = "00";
          this.seconds = "00";
          clearInterval(this.timer); // 停止定时器
        } else {
          // 计算剩余的小时、分钟和秒数
          this.hours = this.formatTime(Math.floor((timeLeft / (1000 * 60 * 60)) % 24));
          this.minutes = this.formatTime(Math.floor((timeLeft / (1000 * 60)) % 60));
          this.seconds = this.formatTime(Math.floor((timeLeft / 1000) % 60));
        }
      }, 1000); // 每秒更新一次
    },
    formatTime(time) {
      // 格式化时间，确保小时、分钟、秒数为两位数
      return time < 10 ? "0" + time : time;
    }
  },
  beforeDestroy() {
    // 在组件销毁时清除定时器，避免内存泄漏
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
}
</script>

<style scoped>
.card-product {
  height: 100vh;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 14px;
}
.steps {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 28px;
}
.step-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.step-row-line {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>